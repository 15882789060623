import React, { FC } from "react";
import { NavLink, Link } from "react-router-dom";
import { routeConfig } from "../App";

interface SubscribeBadgeConfig {
  title: string;
  imgUrl: string;
  linkTo: string;
  style?: any;
}

const subscribeBadgeConfigs: SubscribeBadgeConfig[] = [
  {
    title: "Apple Podcasts",
    imgUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Podcasts_%28iOS%29.svg/240px-Podcasts_%28iOS%29.svg.png",
    linkTo:
      "https://podcasts.apple.com/us/podcast/culture-colander/id1649629563",
  },
  {
    title: "Spotify",
    imgUrl:
      "https://developer.spotify.com/images/guidelines/design/icon3@2x.png",
    linkTo: "https://open.spotify.com/show/02w6hqDGbII2kyXULMTEVW",
    style: { transform: "scale(1.5)" },
  },
  {
    title: "Google Podcasts",
    imgUrl:
      "https://play-lh.googleusercontent.com/BQUYd1Th9Z_XI5wtklPQDHmiNkSOzBakOnpk-Ni8CBTyHb0E7UM5LpyjRW9BWs4fUuVD",
    /* imgUrl: 'https://ssl.gstatic.com/images/branding/product/1x/podcasts_512dp.png', */
    linkTo:
      "https://podcasts.google.com/feed/aHR0cHM6Ly9waW5lY2FzdC5jb20vZmVlZC9jdWx0dXJlLWNvbGFuZGVy",
    /* style: { backgroundColor: 'white' } */
  },
  {
    title: "Overcast",
    imgUrl: "https://overcast.fm/img/appicon-3200.png",
    linkTo:
      "overcast://x-callback-url/add?url=https://overcast.fm/itunes164962956",
  },
];

type HeaderProps = {
  routesConfig: routeConfig[];
  artworkUrl: string;
  description: string;
};

const showNav = false;

const description1 =
  "Culture Colander is a podcast for the confused, the compassionate, and the inextinguishably curious. Sound like you? Then hit the follow button to join Elisa and Audra every week as they sift through the murky waters of the zeitgeist, searching for golden nuggets of wisdom.";
const description2 =
  "In each episode, they'll take on a different social phenomenon (like Only Fans, Child Pageants, or Voluntourism), and hopefully they'll come away having learned a thing or two.";

const Header: FC<HeaderProps> = ({ routesConfig, artworkUrl }) => {
  return (
    <>
      <div className="app-header">
        <div className="title-header">
          <Link className="title" to="/">
            Culture Colander
          </Link>
          <div className="subtitle">{description1}</div>
          <div className="subtitle">{description2}</div>
          <div className="subscribe">
            <div className="subscribe-title">
              Listen for free in your favorite podcast app:
            </div>
            <div className="subscribe-badges">
              {subscribeBadgeConfigs.map((badge) => (
                <a
                  className="subscribe-badge"
                  href={badge.linkTo}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={badge.title}
                >
                  <div className="img-container">
                    <img
                      className="badge-img"
                      src={badge.imgUrl}
                      alt={badge.title}
                      style={badge.style}
                    />
                  </div>
                  <span className="app-name">{badge.title}</span>
                </a>
              ))}
            </div>
          </div>
        </div>
        <a
          className="landing"
          href={subscribeBadgeConfigs[0].linkTo}
          target="_blank"
          rel="noopener noreferrer"
        >
          {artworkUrl ? (
            <img
              className="artwork"
              src={artworkUrl}
              alt="Boys With Bed Frames artwork"
            />
          ) : null}
        </a>
      </div>
      {showNav ? (
        <nav className="nav-bar">
          <ul>
            {routesConfig.map((routeConfig) => (
              <li>
                <NavLink exact activeClassName="selected" to={routeConfig.path}>
                  {routeConfig.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      ) : null}
    </>
  );
};

export default Header;