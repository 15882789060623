import React, { FC } from 'react';
import parse from 'html-react-parser';
import { DateTime } from 'luxon';

export interface Episode {
  title: string,
  content: string,
  isoDate: string,
  itunes: any,
  enclosure: any,
  guid: string
}

type FeedProps = {
  episodes: Episode[];
}

function formatUrl(url: string) {
  // Split the URL into an array to distinguish double slashes from single slashes
  var doubleSlash = url.split('//')

  // Format the strings on either side of double slashes separately
  const f = doubleSlash[1]
    // Insert a word break opportunity after a colon
    .replace(/(?<after>:)/giu, '$1<wbr>')
    // Before a single slash, tilde, period, comma, hyphen, underline, question mark, number sign, or percent symbol
    .replace(/(?<before>[/~.,\-_?#%])/giu, '<wbr>$1')
    // Before and after an equals sign or ampersand
    .replace(/(?<beforeAndAfter>[=&])/giu, '<wbr>$1<wbr>')
    // Reconnect the strings with word break opportunities after double slashes
    ;

  const formatted = [doubleSlash[0], f].join('//');

  console.log({ formatted, f, url });

  return formatted
}

const replacer = (match: any, p1: string) => {
  return `>${formatUrl(p1)}`;
}

const pinecastWatermarkHTML = `<p>This podcast is powered by <a href="https://pinecast.com" rel="nofollow">Pinecast</a>.</p>`;
const anchorRelRegex = /rel="nofollow"/gm;
const newTabHtml = `target='_blank' rel='noopener noreferrer'`;

const Feed: FC<FeedProps> = (props) => {
  const { episodes } = props;

  return (
    <main className='feed'>
      {episodes ? <div>
        {episodes.map((episode, index) => {
          const { title, isoDate, itunes, enclosure, content, guid } = episode;
          const formattedDate = DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_FULL);
          const formattedContent = content.replace(pinecastWatermarkHTML, '').replace(anchorRelRegex, newTabHtml);
          const actuallyFormatted = formattedContent.replace(/>(https.*)<\/a>/g, replacer)
          const parsedContent = parse(actuallyFormatted);
          console.log({ parsedContent, content, actuallyFormatted });

          return (
            <article className='post' key={guid} id={`${episodes.length - index}`}>
              <h2>{title}</h2>
              <div className='metadata'>
                <time dateTime={isoDate}>
                  {formattedDate}
                </time>
                <div>
                  {itunes.duration} • <a href={enclosure.url} download>Download</a>
                </div>
              </div>
              <audio controls src={enclosure.url} />
              <div className="subtitle">
                {itunes.subtitle}
              </div>
              <div className='show-notes'>
                <h3>Show Notes:</h3>
                {parsedContent}
              </div>
            </article>
          )
        })}
      </div> : null}
    </main>
  )
};

export default Feed;

// TODO: Show the size of the file? Maybe not needed...
// check out: https://github.com/dimdenGD/url-file-size/blob/main/index.js
// check out: https://www.npmjs.com/package/remote-file-size
// Note: Seems this should be done using a HEAD request
// check out: https://nodejs.org/api/http.html#http_http_request_url_options_callback
// useEffect(() => {
//   request(
//     {
//       method: 'HEAD',
//       host: 'https://traffic.libsyn.com/atpfm/atp415.mp3',
//       hostname: 'https://traffic.libsyn.com/atpfm/atp415.mp3'
//     }, (res) => {
//     console.log({res});
//   }).on('error', (err) => {
//     console.error({err});
//   }).end();
// }, []);