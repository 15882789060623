import React, { useEffect, useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './App.scss';
import Feed from './Components/Feed';
import Header from './Components/Header';
import Feedback from './Components/Feedback';
import Parser from 'rss-parser';
import { TopBar } from "./Components/TopBar";
import { Routes } from "./Routes";

export interface routeConfig {
  title: string;
  path: string;
  component: any;
  hideHeader?: boolean;
}

const routesConfig: routeConfig[] = [
  {
    title: 'Episodes',
    path: '/',
    component: Feed
  },
  {
    title: 'Feedback',
    path: '/feedback',
    component: Feedback
  },
  {
    title: 'Patreon',
    path: '/patreon',
    component: Feedback
  }
]

const CC_URL = 'https://pinecast.com/feed/culture-colander';

function App() {
  const [feedData, setFeedData] = useState<any>();

  const setData = (newData: any) => {
    console.log({ newData });
    setFeedData(newData);

  }

  const parser = new Parser();

  useEffect(() => {
    (async () => {
      fetch(CC_URL)
        .then(response => response.text())    // one extra step
        .then(async (data) => {
          const feed = await parser.parseString(data);
          setData(feed);
        })
        .catch(error => {
          console.error(error)
        })
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <div className="App">
        <TopBar />
        <div className='header-container'>
          <Header artworkUrl={feedData ? feedData.image.url : ''} routesConfig={routesConfig} description={feedData ? feedData.description : ''} />
        </div>
        <div className='feed-container'>
          <Routes episodes={feedData ? feedData.items : []} />
        </div>
      </div>
    </Router>
  );
}

export default App;
